import createLucideIcon from '../createLucideIcon';

/**
 * @component @name MessageCircle
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMyAyMSAxLjktNS43YTguNSA4LjUgMCAxIDEgMy44IDMuOHoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/message-circle
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const MessageCircle = createLucideIcon('MessageCircle', [
  ['path', { d: 'm3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z', key: 'v2veuj' }],
]);

export default MessageCircle;
